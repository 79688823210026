import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Fetch avatar when component mounts
  useEffect(() => {
    fetchAvatar();
    // If user is adventurer, fetch character sheet
    if (currentUser?.role === 'adventurer') {
      fetchCharacterSheet();
    }
  }, []);

  // Fetch user's avatar from the server
  const fetchAvatar = async () => {
    try {
      if (!currentUser) return;
      
      const response = await fetch(`${API_URL}/avatar`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setAvatarUrl(imageUrl);
      } else {
        console.error('Failed to fetch avatar');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  // Fetch user's character sheet
  const fetchCharacterSheet = async () => {
    try {
      setIsLoadingCharacter(true);
      setCharacterError('');
      
      const response = await fetch(`${API_URL}/character-sheet`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch character sheet');
      }
      
      const data = await response.json();
      setCharacterSheet(data);
    } catch (err) {
      console.error('Error fetching character sheet:', err);
      setCharacterError(err.message || 'An error occurred while fetching character sheet');
    } finally {
      setIsLoadingCharacter(false);
    }
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('avatar', file);
      
      // Upload the file to the server
      const response = await fetch(`${API_URL}/upload/avatar`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload avatar');
      }
      
      // Update the avatar preview with the new image
      const imageUrl = URL.createObjectURL(file);
      setAvatarUrl(imageUrl);
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
      
      // Reset the file input
      e.target.value = '';
    }
  };

  // Format date string
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Handle tab selection
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle logout
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="adventurer-dashboard">
      <header className="dashboard-header">
        <h1>Adventurer Dashboard</h1>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </header>
      
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'character' ? 'active' : ''}`}
          onClick={() => handleTabClick('character')}
        >
          Character Sheet
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            {/* Overview tab content */}
            <h3>Welcome, {currentUser?.displayName || currentUser?.email || 'Adventurer'}</h3>
            
            <div className="gm-messages">
              <h4>Messages from the Game Master</h4>
              {messages && messages.length > 0 ? (
                <div className="message-list">
                  {messages.map((message, index) => (
                    <div key={index} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">From: {message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div 
                        className="message-content"
                        dangerouslySetInnerHTML={renderHtml(message.content)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-messages">
                  <p>No messages from the GM yet.</p>
                </div>
              )}
            </div>
            
            <div className="last-session">
              <h4>Last Session Recap</h4>
              {lastSession ? (
                <div className="session-recap">
                  <div 
                    className="message-content"
                    dangerouslySetInnerHTML={renderHtml(lastSession.content)}
                  />
                  <span className="message-date">{formatDate(lastSession.date)}</span>
                </div>
              ) : (
                <div className="no-recap">
                  <p>No session recap available.</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'character' && (
          <div className="character-sheet-tab">
            <h3>Character Sheet</h3>
            
            {isLoadingCharacter ? (
              <div className="loading-users">
                <div className="loading-spinner"></div>
                <p>Loading character sheet...</p>
              </div>
            ) : characterError ? (
              <div className="no-character-sheet">
                <h4>Character Sheet Not Found</h4>
                <p>{characterError}</p>
                <p className="admin-note">Please contact an admin to have your character sheet uploaded.</p>
              </div>
            ) : !characterSheet ? (
              <div className="no-character-sheet">
                <h4>No Character Sheet Found</h4>
                <p>Your character sheet hasn't been uploaded yet.</p>
                <p className="admin-note">Please contact an admin to have your character sheet uploaded.</p>
              </div>
            ) : (
              <div className="character-sheet-container">
                <div className="character-sheet-header">
                  <div className="character-portrait">
                    {characterSheet.img ? (
                      <img src={characterSheet.img} alt={`${characterSheet.name} portrait`} />
                    ) : (
                      <div className="character-portrait-placeholder">
                        <span>{characterSheet.name ? characterSheet.name[0] : '?'}</span>
                      </div>
                    )}
                  </div>
                  
                  <div className="character-info">
                    <h2 className="character-name">{characterSheet.name || 'Unnamed Character'}</h2>
                    
                    <div className="character-details">
                      <div className="character-detail">
                        <span className="detail-label">Class</span>
                        <span className="detail-value">{characterSheet.system?.details?.class || 'Unknown'}</span>
                      </div>
                      
                      <div className="character-detail">
                        <span className="detail-label">Level</span>
                        <span className="detail-value">{characterSheet.system?.details?.level || '1'}</span>
                      </div>
                      
                      <div className="character-detail">
                        <span className="detail-label">Race</span>
                        <span className="detail-value">{characterSheet.system?.details?.race || 'Unknown'}</span>
                      </div>
                      
                      <div className="character-detail">
                        <span className="detail-label">Background</span>
                        <span className="detail-value">{characterSheet.system?.details?.background || 'Unknown'}</span>
                      </div>
                      
                      <div className="character-detail">
                        <span className="detail-label">Alignment</span>
                        <span className="detail-value">{characterSheet.system?.details?.alignment || 'Unknown'}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="character-sheet-section">
                  <h3 className="section-title">Abilities</h3>
                  <div className="abilities-container">
                    {characterSheet.system && characterSheet.system.abilities && Object.entries(characterSheet.system.abilities).map(([key, ability]) => (
                      <div key={key} className="ability-score">
                        <div className="ability-name">{key.toUpperCase()}</div>
                        <div className="ability-value">{ability.value || 10}</div>
                        <div className="ability-modifier">
                          {getAbilityModifier(ability.value) >= 0 ? '+' : ''}
                          {getAbilityModifier(ability.value)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                <div className="character-sheet-section">
                  <h3 className="section-title">Combat</h3>
                  <div className="combat-stats">
                    <div className="combat-stat">
                      <div className="stat-name">Armor Class</div>
                      <div className="stat-value">{characterSheet.system?.attributes?.ac?.value || '10'}</div>
                    </div>
                    
                    <div className="combat-stat">
                      <div className="stat-name">Initiative</div>
                      <div className="stat-value">{characterSheet.system?.attributes?.init?.total || '+0'}</div>
                    </div>
                    
                    <div className="combat-stat">
                      <div className="stat-name">Speed</div>
                      <div className="stat-value">{characterSheet.system?.attributes?.movement?.walk || '30'} ft</div>
                    </div>
                    
                    <div className="combat-stat hp-container">
                      <div className="stat-name">Hit Points</div>
                      <div className="hp-bar">
                        <div 
                          className="hp-fill" 
                          style={{
                            width: `${Math.min(100, Math.max(0, ((characterSheet.system?.attributes?.hp?.value || 0) / (characterSheet.system?.attributes?.hp?.max || 1)) * 100))}%`
                          }}
                        ></div>
                        <div className="hp-text">
                          {characterSheet.system?.attributes?.hp?.value || '0'} / {characterSheet.system?.attributes?.hp?.max || '0'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="character-sheet-section">
                  <h3 className="section-title">Skills</h3>
                  <div className="skills-container">
                    {characterSheet.system && characterSheet.system.skills && Object.entries(characterSheet.system.skills).map(([key, skill]) => {
                      const abilityKey = skill.ability;
                      const abilityMod = characterSheet.system.abilities && characterSheet.system.abilities[abilityKey] 
                        ? getAbilityModifier(characterSheet.system.abilities[abilityKey].value) 
                        : 0;
                      const profValue = skill.value ? (skill.value >= 1 ? 2 : 1) : 0;
                      const profBonus = characterSheet.system.attributes?.prof?.value || 2;
                      const totalMod = abilityMod + (profValue * profBonus);
                      
                      return (
                        <div key={key} className="skill-item">
                          <span className={`skill-proficient ${profValue ? 'active' : ''}`}></span>
                          <span className="skill-name">{skill.label}</span>
                          <span className="skill-ability">({abilityKey.toUpperCase()})</span>
                          <span className="skill-modifier">
                            {totalMod >= 0 ? '+' : ''}
                            {totalMod}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                
                {characterSheet.items && (
                  <>
                    <div className="character-sheet-section">
                      <h3 className="section-title">Equipment</h3>
                      <div className="equipment-list">
                        {characterSheet.items
                          .filter(item => item.type === 'weapon' || item.type === 'equipment' || item.type === 'backpack' || item.type === 'consumable')
                          .map((item, index) => (
                            <div key={index} className="equipment-item">
                              <div className="equipment-name-container">
                                <span className="equipment-name">{item.name}</span>
                                {item.system.quantity > 1 && (
                                  <span className="equipment-quantity">×{item.system.quantity}</span>
                                )}
                              </div>
                              {item.system.description?.value && (
                                <div 
                                  className="equipment-description"
                                  dangerouslySetInnerHTML={renderHtml(item.system.description.value)}
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    
                    <div className="character-sheet-section">
                      <h3 className="section-title">Features & Traits</h3>
                      <div className="features-list">
                        {characterSheet.items
                          .filter(item => item.type === 'feat' || item.type === 'feature')
                          .map((item, index) => (
                            <div key={index} className="feature-item">
                              <div className="feature-name">{item.name}</div>
                              {item.system.description?.value && (
                                <div 
                                  className="feature-description"
                                  dangerouslySetInnerHTML={renderHtml(item.system.description.value)}
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    
                    {characterSheet.items.some(item => item.type === 'spell') && (
                      <div className="character-sheet-section">
                        <h3 className="section-title">Spells</h3>
                        <div className="spell-slots-container">
                          {characterSheet.system?.spells && Object.entries(characterSheet.system.spells)
                            .filter(([key, slot]) => key !== 'pact' && key.startsWith('spell') && slot.max > 0)
                            .map(([key, slot]) => {
                              const level = key.replace('spell', '');
                              return (
                                <div key={key} className="spell-slot-item">
                                  <div className="spell-slot-label">Level {level}</div>
                                  <div className="spell-slot-value">
                                    {slot.value || 0}/{slot.max || 0}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        
                        {/* Group spells by level */}
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(level => {
                          const spellsAtLevel = characterSheet.items
                            .filter(item => item.type === 'spell' && item.system.level === level);
                            
                          if (spellsAtLevel.length === 0) return null;
                          
                          return (
                            <div key={level} className="spell-level-section">
                              <h4 className="spell-level-header">
                                {level === 0 ? 'Cantrips' : `Level ${level} Spells`}
                              </h4>
                              <div className="spell-list">
                                {spellsAtLevel.map((spell, index) => (
                                  <div key={index} className="spell-item">
                                    <div className="spell-name">{spell.name}</div>
                                    {spell.system.description?.value && (
                                      <div 
                                        className="spell-description"
                                        dangerouslySetInnerHTML={renderHtml(spell.system.description.value)}
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    
                    <div className="character-sheet-section">
                      <h3 className="section-title">Biography</h3>
                      <div className="biography-content">
                        {characterSheet.system?.details?.biography?.value ? (
                          <div dangerouslySetInnerHTML={renderHtml(characterSheet.system.details.biography.value)} />
                        ) : (
                          <p>No biography available.</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                
                <div className="character-sheet-refresh">
                  <button 
                    className="refresh-button" 
                    onClick={fetchCharacterSheet}
                    disabled={isLoadingCharacter}
                  >
                    {isLoadingCharacter ? 'Refreshing...' : 'Refresh Character Sheet'}
                  </button>
                  <p className="admin-note">Character sheets can only be uploaded by admins.</p>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Your avatar" />
                ) : (
                  <div className="avatar-placeholder">
                    <span>{currentUser?.displayName?.[0] || currentUser?.email?.[0] || '?'}</span>
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Update Avatar</h4>
                <p>Upload a new avatar image (max 2MB).</p>
                
                <label className="upload-button">
                  Choose Image
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {isUploading && <div className="loading-spinner"></div>}
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Name</div>
                <div className="info-value">{currentUser?.displayName || 'Not set'}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Email</div>
                <div className="info-value">{currentUser?.email}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser?.createdAt)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 